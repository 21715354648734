
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";
import Select from 'react-select';
import InputForm from '../publicRegister/components/Input';
import SelectForm from '../publicRegister/components/Select';

// Data
import { Divider, Grid, Input } from "@mui/material";

import SoftAlert from "components/SoftAlert";

function DataFormParticipant({formData, setState, selectState, errorFields, statusForm}) {

    const inputsFormDefault = [
        {name: "Nombre", key:"name", type:"text", requerid: true, default: true},
        {name: "Correo electrónico", key:"email", type:"email", requerid: true, default: true},
        {name: "Teléfono", key:"phone", type:"text", requerid: false, default: true},
        {name: "Costo", key:"cost", type:"number", requerid: false, default: true},
    ]

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Actualizar información del participante</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                statusForm.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={statusForm.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {statusForm.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                {
                  inputsFormDefault.map( item =>(
                      <InputForm item={item} setState={setState} formData={formData} errorFields={errorFields}/>
                  ))
                }
                <Divider />
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}

DataFormParticipant.propTypes = {
  formData: PropTypes.object,
  setState: PropTypes.func,
  errorFields: PropTypes.string,
  statusForm: PropTypes.string,
};

export default DataFormParticipant;
