/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

import defaultCover from "assets/images/curved-images/curved0.jpg";

import logoDefault from "assets/images/logo.jpeg"
import { Icon } from "@mui/material";

function Header({formData, modalCoverLogo}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light />
      <SoftBox
        onClick = {()=>modalCoverLogo('Imagen Cover', 'cover')}
        style = {{cursor: 'pointer' }}
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.1),
            rgba(gradients.dark.state, 0.1)
          )}, url(${formData.cover ?? defaultCover})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              onClick = {()=>modalCoverLogo('Imagen Logo', 'logo')}
              src={formData.logo ?? logoDefault}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
              style={{ backgroundColor: 'white', cursor: 'pointer', height: 143 }}
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {formData.name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {formData.sede}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent" }}
              >
                {/* <Tab label="Programa" icon={<Document color="info" />} /> */}
                <Tab 
                  label={
                    <a href={`/register/${formData.slug}/edit/1`} target="_blank" rel="noopener noreferrer">Registro General</a>
                  }
                  icon={<Icon color="info">link</Icon>} 
                  />
                <Tab 
                  label={
                    <a href={`/register/${formData.slug}/edit/2`} target="_blank" rel="noopener noreferrer">Registro Especial</a>
                  }
                  icon={<Icon color="info">link</Icon>} 
                  />
                {/* <Tab label="Publicado" icon={<Icon color="success">visibility</Icon>} /> */}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
