import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { useNavigate } from "react-router-dom";


// Data
import { Checkbox, Grid, Input, Link, Modal } from "@mui/material";

import SoftAlert from "components/SoftAlert";

import { insertDataJson, getData, validateForm} from 'tools/helpers';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import HeaderEvent from 'layouts/event/components/HeaderEvent';
import SoftButton from 'components/SoftButton';
import InputForm from './components/Input';
import SelectForm from './components/Select';
import { generateSlug } from 'tools/helpers';
import Participants from './participants';

import ImgGeneral  from '../../assets/images/instrucciones_registro_general.png'
import ImgEspecial  from '../../assets/images/instrucciones_registro_especial.png'


function PublicRegister() {
  const navigate = useNavigate();
  const { slug, register_form_id } = useParams();
  const emptyData = {
    name : '',
    email: '',
    phone: '',
    coupon: '',
    membership: ''
  }
  
  const [formData, setFormData] = useState(emptyData);
  const [membershipInclude, setMembershipInclude] = useState(false);
  const [formDataParticipants, setFormDataParticipants] = useState([]);
  const [statusForm, setStatusForm] = useState({status: null, message: ""});
  const [errorFields, setErrorsFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [open, setOpen] = useState(false);

  const [eventData, setEventData] = useState({});
  const [listForms, setListForms] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [requireBill, setRequireBill] = useState(false);
  
  

  const inputsFormDefault = [
    {name: "Nombre", key:"name", type:"text", requerid: true, default: true, helperText: '' },
    {name: "Correo electrónico", key:"email", type:"email", requerid: true, default: true, helperText: 'Solo un email/correo por registro' },
    {name: "Teléfono", key:"phone", type:"text", requerid: false, default: true, helperText: '' },
  ]

  const setState = (e) => {

    const conditionWithMember = e.target.value.includes("con membresía") || e.target.value.includes("con membresia");
    const conditionWithoutMember = e.target.value.includes("sin membresía") || e.target.value.includes("sin membresia");

    if(conditionWithMember){
      setMembershipInclude(true);
    }

    if(conditionWithoutMember){
      setMembershipInclude(false);
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getEvent = async () => {
    const route = `Event-Slug/${slug}`;
    const send = await getData(route);
    if (send.code === 200) {
      setEventData(send.data);
      getSponsors(send.data.id);
    }
  };

  const getCatalogs = async () => {
    const route = `Catalog-Form/${slug}/${register_form_id}`;
    const send = await getData(route);
    if (send.code === 200) {
      setListForms(send.data);
      const listRequired = send.data
        .filter(item => parseInt(item.status) === 1)
        .map(item => item.key);
      listRequired.push('name', 'email');

      setRequiredFields(listRequired)
    }
  };

  const getSponsors = async (event_id) => {
    const route = `Sponsors/${event_id}`;
    const send = await getData(route);
    if (send.code === 200) {
      setSponsors(send.data);
    }
  };

  const deleteParticipants = async (participant)=>{
    const newList = formDataParticipants.filter(item=> (item.name !== participant.name) || (item.email !== participant.email));
    setFormDataParticipants(newList);
  }

  const validateCoupons = async (list, latestCoupon) => {
    const newData = {
      coupons: list,
      latest_coupon: latestCoupon
    };
    const route = `Coupon-Validate`;
    const send = await insertDataJson(route, newData);
    if (send.code === 200) {
      setStatusForm({status: true, message: send.message, label: "info"});
      return true;
    }

    setStatusForm({status: true, message: send.message, label: "warning"});
    return false;
  }

  const validateEmailRegister = async(email) =>{
    const route = `Validate-Mail-Register`;
    const newData = {
      event_id: eventData.id,
      email
    }
    const send = await insertDataJson(route, newData);

    if (send.code === 200) {
      return false;
    }
    return true;
  }

  const addParticipants = async() =>{
    setStatusForm({status: null, message: ""});

    let duplicateEmailRewgister = false;
    duplicateEmailRewgister = await validateEmailRegister(formData.email);
    if(duplicateEmailRewgister){
      setStatusForm({status: true, message: `Ya existe un registro con el correo ${formData.email}.`, label: "warning",});
      return;
    }

    const duplicateEmail = formDataParticipants.filter(item => (item.email).toLowerCase() === (formData.email).toLowerCase());
    if(duplicateEmail.length > 0){
      setStatusForm({status: true, message: "Correo duplicado: Por favor ingrese un correo distinto.", label: "warning",});
      return;
    }

    if(parseInt(register_form_id) === 2 && parseInt(eventData.required_coupon) === 1){
      requiredFields.push('coupon');
    }

    const validationErrors = validateForm(formData, requiredFields);
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    if(membershipInclude && formData.membership === ''){
      setStatusForm({status: true, message: "Número de membresía obligario", label: "error",});
      return;
    }

    const newList = [...formDataParticipants];
    newList.push({
        ...formData,
        identifier_register: generateSlug(`${formData.name}-${eventData?.name}`),
        type_visitor: parseInt(register_form_id) === 1 ? 'Registro general' : 'Registro especial'
      });

    if(formData.coupon !== ''){      
      const couponsData = newList
        .filter(item => item.coupon !== '')
        .map(item => item.coupon);
  
      const isValidCoupon = await validateCoupons(couponsData, formData.coupon);

      if(!isValidCoupon){
        return;
      }
    }

    setFormDataParticipants(newList);
      const empty = {};
      listForms.forEach(item=>{
        if(item.type === 'input'){
          empty[item.key] = '';
        }else{
          empty[item.key] = formData[item.key];
        }
      });
    setFormData({
        ...empty, 
        ...emptyData,
        coupon: formData.coupon ?? ''
      });

  }

  const sendForm = async() =>{
    if(formDataParticipants.length === 0){
      setStatusForm({status: true, message: "Por favor agregue un registro", label: "warning",});
      return;
    }
    const newData = {
      participants: formDataParticipants,
      slug: slug,
      membership: membershipInclude ? formData.membership : ''
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Register`;
    const send = await insertDataJson(route, newData);

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha realizado el pre-registro satisfactoriamente, redireccionando...",
        label: "success",
      });

      if(parseInt(register_form_id) === 1){
        setOpen(true);
        await sendMailNotification(newData, 'general');
        return;
      }

      setOpen(true);
      await sendMailNotification(newData, 'especial');
      return;
    }

    if (send.status === "error") {
      requiredFields.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }

    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const sendMailNotification = async (dataMail, type) => {
    const route = `Mail-Preregister`;
    const newData = {
      ...dataMail,
      type: type
    }
    const send = await insertDataJson(route, newData);
  }

  useEffect(()=>{
    getEvent();
    getCatalogs();
  },[])

  return (
    <PageLayout>
      <SoftBox p={5}>
        <HeaderEvent
        formData = {eventData}
        />
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8} justifyContent="center" alignContent="center" style={{display: 'flex'}}>
            <Grid p={4} container  style={{ maxWidth: 1100 }}>
              <Grid md={12}>
                <SoftTypography variant="h2" fontWeight="bold" >{parseInt(register_form_id) === 1 ? 'Registro General' : 'Registro Especial'}</SoftTypography>
              </Grid>
              <Grid md={12} mt={2} style={{ lineHeight: 0 }}>
                {
                  parseInt(register_form_id) === 1 ?
                    <img style={{ maxWidth: 400, width: '100%' }} src={ImgGeneral} />
                  : <img style={{ maxWidth: 400, width: '100%' }} src={ImgEspecial} />
                }
              </Grid>
              {/* <Grid md={12} mt={2} style={{ lineHeight: 0 }}>
                <SoftTypography variant="caption">1. Llenar tus datos.</SoftTypography>
                <br />
                <SoftTypography variant="caption">2. Seleccionar tu perfil correspondiente.</SoftTypography>
                <br />
                <SoftTypography variant="caption">3. Dar clic en AGREGAR REGISTRO.</SoftTypography>
                <br />
                <SoftTypography variant="caption">4. Dar clic en ENVÍAR REGISTROS.</SoftTypography>
                <br />
                <SoftTypography variant="caption">5. Verificar tu correo o mail.</SoftTypography>
                {
                  eventData.bill_register_2 || eventData.bill_register_1 ?
                  <>
                    <br />
                    <SoftTypography variant="caption">* Si requiere factura, dar clic y proporcionar tus datos fiscales.</SoftTypography>
                  </>
                  : null
                }
                <br />
                <SoftTypography variant="caption">* El correo es de manera individual. No se puede repetir el mismo en el evento.</SoftTypography>
              </Grid> */}
              <Grid md={12}>
                {
                  errorFields.length > 0 ?
                    <SoftAlert mt={1} color="error"> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        Campos requeridos (*)
                      </SoftTypography>
                    </SoftAlert>
                  : null
                }
              </Grid>
              <Grid pt={3} md={12} container >
                {
                  inputsFormDefault.map( item =>(
                      <InputForm item={item} setState={setState} formData={formData} errorFields={errorFields}/>
                  ))
                }
                {
                  listForms?.map( item =>(
                    item.type === "select" ?
                    <>
                      <SelectForm item={item} setState={setState} formData={formData} errorFields={errorFields}/>
                      {
                        parseInt(register_form_id) === 1 && membershipInclude ?
                          <Grid md={6} px={1}>
                            <SoftTypography variant="caption"> Número de membresía:</SoftTypography>
                            <Input
                              type = "number"
                              name = "membership"
                              value = {formData.membership}
                              onChange={(e)=>{
                                if (e.target.value.length <= 10) {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }
                              }}
                              className= {membershipInclude && formData.membership === '' ? 'error-field' : null}
                              padding = {10}
                            />
                          </Grid>
                        : null
                      }
                    </>
                    :
                      <InputForm item={item} setState={setState} formData={formData} errorFields={errorFields}/>
                  ))
                }
                {
                  parseInt(register_form_id) === 2 ?
                    <Grid md={6} px={1}>
                      <SoftTypography variant="caption"> Cupón:</SoftTypography>
                      <Input 
                        name = "coupon"
                        value = {formData.coupon}
                        onChange={setState}
                        className= {errorFields.includes("coupon") ? 'error-field' : null}
                        padding = {10}
                      />
                    </Grid>
                  : null
                }
              </Grid>
              {
                parseInt(eventData.bill_register_2) === 1 && parseInt(register_form_id) === 2 ?
                  <Grid container md={12} p={1}>
                    <Grid md={6} mt={2} px={1}>
                      <Checkbox
                          checked= {requireBill}
                          color='secondary'
                          onChange = {()=>{
                            setRequireBill(!requireBill);
                          }}
                      />
                      <SoftTypography variant="caption">¿Agregar datos de facturación?</SoftTypography>
                    </Grid>
                  </Grid>
                : parseInt(eventData.bill_register_1) === 1 && parseInt(register_form_id) === 1?
                  <Grid container md={12} p={1}>
                    <Grid md={6} mt={2} px={1}>
                      <Checkbox
                          checked= {requireBill}
                          color='secondary'
                          onChange = {()=>{
                            setRequireBill(!requireBill);
                          }}
                      />
                      <SoftTypography variant="caption">¿Agregar datos de facturación?</SoftTypography>
                    </Grid>
                  </Grid>
                : null
              }
              {
                  (requireBill && parseInt(register_form_id) === 1) || (requireBill && parseInt(register_form_id) === 2) ? 
                    <Grid container md={12} px={1}>
                      <Grid md={6} px={1}>
                        <SoftTypography variant="caption"> Razón social*:</SoftTypography>
                        <Input 
                          name = "bussiness_name"
                          value = {formData.bussiness_name}
                          onChange={setState}
                          className= {errorFields.includes("bussiness_name") ? 'error-field' : null}
                          padding = {10}
                        />
                      </Grid>
                      <Grid md={6} px={1}>
                        <SoftTypography variant="caption"> RFC*:</SoftTypography>
                        <Input 
                          name = "rfc"
                          value = {formData.rfc}
                          onChange={setState}
                          className= {errorFields.includes("rfc") ? 'error-field' : null}
                          padding = {10}
                        />
                      </Grid>
                      <Grid md={6} px={1}>
                        <SoftTypography variant="caption"> Dirección*:</SoftTypography>
                        <Input 
                          name = "address"
                          value = {formData.address}
                          onChange={setState}
                          className= {errorFields.includes("address") ? 'error-field' : null}
                          padding = {10}
                        />
                      </Grid>
                      <Grid md={3} px={1}>
                        <SoftTypography variant="caption"> Uso de CFDI*:</SoftTypography>
                        <select
                          className='select-custom'
                          name = "cfdi"
                          onChange={setState}
                        >
                          <option value="">Seleccione una opción</option>
                          <option value="Gastos en general">Gastos en general</option>
                        </select>
                      </Grid>
                      <Grid md={3} px={1}>
                        <SoftTypography variant="caption"> Regímen*:</SoftTypography>
                        <select
                          className='select-custom'
                          name = "regime"
                          onChange={setState}
                        >
                          <option value="">Seleccione una opción</option>
                          <option value="General de Ley Personas Morales">General de Ley Personas Morales</option>
                          <option value="Personas Morales con Fines no Lucrativos">Personas Morales con Fines no Lucrativos</option>
                          <option value="Sueldos y Salarios e Ingresos Asimilados a Salarios">Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                          <option value="Personas Físicas con Actividades Empresariales y Profesionales">Personas Físicas con Actividades Empresariales y Profesionales</option>
                          <option value="Sin obligaciones fiscales">Sin obligaciones fiscales</option>
                          <option value="Arrendamiento">Arrendamiento</option>
                          <option value="Régimen de Enajenación o Adquisición de Bienes">Régimen de Enajenación o Adquisición de Bienes</option>
                          <option value="Residentes en el Extranjero sin Establecimiento Permanente en México">Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                          <option value="Ingresos por Dividendos (socios y accionistas)">Ingresos por Dividendos (socios y accionistas)</option>
                          <option value="Ingresos por intereses">Ingresos por intereses</option>
                          <option value="Régimen de los ingresos por obtención de premios">Régimen de los ingresos por obtención de premios</option>
                          <option value="Sociedades Cooperativas de Producción que optan por diferir sus ingresos">Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                          <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                          <option value="Opcional para Grupos de Sociedades">Opcional para Grupos de Sociedades</option>
                          <option value="Coordinados">Coordinados</option>
                          <option value="Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                          <option value="Régimen Simplificado de Confianza">Régimen Simplificado de Confianza</option>
                        </select>
                      </Grid>
                    </Grid>
                  : null
                }
            <Grid md={12}>
              {
                  statusForm.status ? (
                    <SoftBox mt={1} extAlign="center" p={1}>
                      <SoftAlert color={statusForm.label}> 
                        <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                          {statusForm.message}
                        </SoftTypography>
                      </SoftAlert>
                    </SoftBox>
                  ): null
                }
            </Grid>
            <Grid xs={12} md={6} px={1} mt={2} spacing={3}>
              <SoftButton
                    md={6}
                    variant="gradient"
                    color= "info"
                    size="medium"
                    onClick = {()=>addParticipants()}
                >
                    Agregar registro
              </SoftButton>
              {/* <SoftButton
                    style={{ marginLeft: 5 }}
                    md={6}
                    variant="gradient"
                    color= "primary"
                    size="medium"
                    onClick = {()=>sendForm('send')}
                >
                    Enviar regsitro
              </SoftButton> */}
            </Grid>
          </Grid>
          </SoftBox>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <Participants data={formDataParticipants} label="Registros" formData={formData} sendForm={sendForm} deleteParticipants={deleteParticipants}/>
            </SoftBox>
          </SoftBox>
        </Card>
        <Grid container md={12} mt={2} justifyContent="center" alignItems="center">
          {
            sponsors.length > 0 ?
              sponsors.map(item=>(
                <Card item container style={{ margin: 2 }} justifyContent="center" alignItems="center">
                  <img style={{ padding: 15, borderRadius: 5 }} width="100" src={item.img} />
                </Card>

              ))
              : null
          }
        </Grid>
      </SoftBox>
      <Modal
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={styleDialog} md={12}>
          <Grid  md={12} justifyContent='center'>
            <Grid container md={12} justifyContent='center'>
                <img width={100} src={eventData.logo} />
            </Grid>
            <Grid container textAlign="center" justifyContent='center' alignItems="center"  mb={1}>
                <SoftTypography variant="h4">¡Gracias!</SoftTypography>
                <SoftTypography style={{ padding: 5 }} variant="caption">Te hemos enviado un correo para validar tu registro</SoftTypography>
            </Grid>
            <Grid  md={12} spacing={2} textAlign='center'>
                <Link 
                href={eventData.web} 
                className='btn-link-blue'
                >ACEPTAR</Link>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
    </PageLayout>
  );
}

const styleDialog = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '220px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default PublicRegister;
